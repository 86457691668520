import React, {useEffect, useCallback, useState} from 'react';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';
import {HISTORY_FIELDS} from '../../dictionary';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {timestampFormat} from '../../Utils/DateUtil';
import {errorHandler} from '../../errors';
const appConfig = require('../../data.json');

const customMapping = {
  UserProfile: {
    note: (val) => ({bonus_gift: '撒紅利'}[val] || val),
  },
};

function parse(model, value) {
  let result = [];
  if (!HISTORY_FIELDS[model]) {
    return;
  }

  result = Object.keys(value)
    .filter((key) => !!HISTORY_FIELDS[model][key])
    .map(
      (key) =>
        `${HISTORY_FIELDS[model][key]}:${
          typeof customMapping[model]?.[key] === 'function'
            ? customMapping[model]?.[key](value[key], value)
            : value[key]
        }`,
    );
  // through custom mapping or original value

  return result.join('； ');
}

const PAGINATION = {
  limit: 5,
  current: 1,
};

export default function MemberHistoryBlock(props) {
  const {member} = props;
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });

  function filtersChange(obj) {
    setFilters((prev) => ({...prev, ...obj}));
  }

  const getRecords = useCallback(async () => {
    setLoading(true);
    if (member) {
      try {
        let resp = await actions.getHistories({
          query: {instance_id: member.id, model: 'UserProfile'},
          paging: {
            limit: filters.limit,
            offset: (filters.current - 1) * filters.limit,
          },
          sorting: ['-created'],
        });

        let {results, total} = resp;

        setRecords(results);
        setTotal(total);
      } catch (err) {
        errorHandler(err);
      }
    }

    setLoading(false);
  }, [actions, member, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Block>
      <Row style={{justifyContent: 'space-between'}}>
        <h2>點數及紅利變更歷史紀錄</h2>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecords}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>
      <div className="divider" />
      <div>
        <Ant.Table
          loading={loading}
          columns={[
            // {
            //   title: "項目",
            //   key: "model",
            //   dataIndex: "model",
            //   render: model => HISTORY_MODEL[model]
            // },
            {
              title: '時間',
              key: 'created',
              dataIndex: 'created',
              render: (created) => timestampFormat(created),
            },
            {
              title: '訂單編號',
              key: 'order_display_id',
              dataIndex: 'order_display_id',
            },
            {
              title: '變動',
              key: 'value',
              render: (record) => (
                <p style={{margin: 0}}>
                  {parse(record.model, record.value) ||
                    JSON.stringify(record.value)}
                </p>
              ),
            },
            {
              title: '人員',
              key: 'user',
              dataIndex: 'user',
              render: (user) => <p>{user.name || user.username}</p>,
            },
          ]}
          rowKey="id"
          dataSource={records}
          onChange={(pagination) => filtersChange(pagination)}
          pagination={{
            total,
            pageSize: filters.limit,
            current: filters.current,
          }}
        />
      </div>
    </Block>
  );
}
