import React, {useCallback, useEffect, useState} from 'react';
import * as Ant from 'antd';
import ReviewUpgradeDialog from '../../Dialogs/ReviewUpgradeDialog';
import {useOutlet} from 'reconnect.js';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import Center from '../../Widgets/Center';
import Empty from '../../Widgets/Empty';
import {UPGRADE_REQ_TO, UPGRADE_STATUS} from '../../dictionary';
const appConfig = require('../../data.json');

function Item({record, onClick}) {
  return (
    <React.Fragment>
      <div className="row">
        <h4>申請狀態</h4>
        <div>{UPGRADE_STATUS[record.state]}</div>
      </div>
      <div className="row">
        <h4>申請類別</h4>
        <div>申請成為{UPGRADE_REQ_TO[record.req_to]}</div>
      </div>
      <div className="row">
        <h4>拒絕原因</h4>
        <div>{record.reason}</div>
      </div>
      <div className="row">
        <h4>審核</h4>
        <Button
          type="primary"
          disabled={record.state !== 'pending'}
          color={appConfig.colors.sub}
          size="small"
          onClick={() => onClick(record)}>
          審核
        </Button>
      </div>
    </React.Fragment>
  );
}

export default function MemberUpgradeBlock({memberId, onUpdate}) {
  const [selected, setSelected] = useState();
  const [records, setRecords] = useState([]);
  const [actions] = useOutlet('actions');

  const getRecords = useCallback(async () => {
    try {
      let resp = await actions.getRequestUpgrades({
        issuer: memberId,
        ordering: '-created',
      });
      setRecords(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [memberId, actions.getRequestUpgrades]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Block>
      <div>
        <h2>月結會員申請單</h2>
        <div className="subtitle">
          * 如果已核准申請，請不要手動再調整審核狀態以防系統錯亂
        </div>
      </div>
      <div className="divider" />
      <div>
        {records.length > 0 ? (
          <div>
            <Ant.Collapse defaultActiveKey={['0']}>
              {records.map((record, idx) => (
                <Ant.Collapse.Panel
                  key={idx}
                  header={
                    <div style={{display: 'inline-flex', fontSize: 16}}>
                      <div style={{marginRight: 10}}>申請單 # {record.id}</div>
                    </div>
                  }>
                  <Item
                    record={record}
                    onClick={(record) => setSelected(record)}
                  />
                </Ant.Collapse.Panel>
              ))}
            </Ant.Collapse>
          </div>
        ) : (
          <Center>
            <Empty />
          </Center>
        )}
      </div>
      <ReviewUpgradeDialog
        key={new Date().getTime()} // force update to refresh state in this modal
        visible={!!selected}
        onClose={() => setSelected(null)}
        record={selected}
        onRefresh={() => {
          getRecords();
          onUpdate();
        }}
      />
    </Block>
  );
}
