import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, message, Spin, Select, Input} from 'antd';
import {UPGRADE_STATUS, MONTHLY_TYPE} from '../dictionary';
import {useOutlet} from 'reconnect.js';
import {errorHandler, ErrUpgrade} from '../errors';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Anchor from '../Widgets/Anchor';

export default function ReviewUpgradeDialog({
  visible,
  onClose,
  onRefresh,
  ...props
}) {
  const [record, setRecord] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [actions] = useOutlet('actions');

  useEffect(() => {
    if (props.record) {
      try {
        let parsed = JSON.parse(props.record.data);
        setRecord({
          monthly_quota: 10000,
          monthly_type: parsed?.monthly_type || 'last_in_month',
          monthly_pay_buffer: 30,
          ...props.record,
        });
        setData(parsed);
      } catch (err) {
        message.error('取得資料失敗！');
      }
    }
  }, [props.record]);

  const readFile = async (key) => {
    try {
      setLoading(true);

      if (!key) {
        throw new ErrUpgrade('找不到檔名');
      }
      let {url} = await actions.getPrivateFile(key);

      window.open(url, '_blank');
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  const valid = () => {
    if (!record.monthly_quota) {
      throw new ErrUpgrade('額度不能為0');
    }
    if (!record.monthly_pay_buffer) {
      throw new ErrUpgrade('繳費緩衝期不能為0');
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      valid();

      await actions.reviewRequestUpgrade({
        id: record.id,
        state: record.state,
        reason: record.reason,
        monthly_quota: record.monthly_quota,
        monthly_type: record.monthly_type,
        monthly_pay_buffer: record.monthly_pay_buffer,
      });
      onRefresh();
      onClose();
      setRecord(null);
      setData({});
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  return (
    <Modal
      title="會員申請審核"
      visible={visible}
      confirmLoading={loading}
      onOk={() => submit()}
      onCancel={onClose}
      okText="儲存"
      cancelText="取消">
      {record ? (
        <Block>
          <div style={{padding: 0}}>
            <div className="row">
              <h4>審核狀態</h4>
              <Select
                value={record?.state}
                onChange={(value) => {
                  setRecord((prev) => ({...prev, state: value}));
                }}>
                {Object.keys(UPGRADE_STATUS).map((status, idx) => {
                  return (
                    <option key={idx} value={status}>
                      {UPGRADE_STATUS[`${status}`]}
                    </option>
                  );
                })}
              </Select>
            </div>

            {record.state === 'rejected' ? (
              <div className="row" style={{marginTop: 10}}>
                <h4>拒絕原因</h4>
                <Input
                  value={record?.reason}
                  onChange={(e) => {
                    let value = e.target.value;
                    setRecord((prev) => ({...prev, reason: value}));
                  }}
                />
              </div>
            ) : null}

            <div className="row">
              <h4>月結額度</h4>
              <Input
                type="number"
                value={record.monthly_quota}
                onChange={(e) =>
                  setRecord((prev) => ({
                    ...prev,
                    monthly_quota: parseInt(e.target.value),
                  }))
                }
                style={{marginRight: 5}}
              />
              <div>元</div>
            </div>

            <div className="row">
              <h4>月結結算日</h4>
              <Select
                value={record.monthly_type}
                onChange={(value) =>
                  setRecord((prev) => ({...prev, monthly_type: value}))
                }>
                {Object.keys(MONTHLY_TYPE).map((key, idx) => (
                  <Select.Option key={idx} value={key}>
                    {MONTHLY_TYPE[key]}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="row">
              <h4>月結繳費緩衝期</h4>
              <Input
                type="number"
                value={record.monthly_pay_buffer}
                onChange={(e) =>
                  setRecord((prev) => ({
                    ...prev,
                    monthly_pay_buffer: parseInt(e.target.value),
                  }))
                }
                style={{marginRight: 5}}
              />
              <div>天</div>
            </div>

            {record.req_to === 'enterprise' || record.req_to === 'ent_vip' ? (
              <Fragment>
                <div className="row">
                  <h4>公司名稱*</h4>
                  <div>{data?.ent_name || '--'}</div>
                </div>
                <div className="row">
                  <h4>統一編號*</h4>
                  <div>{data?.gui_number || '--'}</div>
                </div>
                <div className="row">
                  <h4>公司電話*</h4>
                  <div>{data?.ent_tel || '--'}</div>
                </div>
                <div className="row">
                  <h4>分機</h4>
                  <div>{data?.tel_ext || '--'}</div>
                </div>
                <div className="row">
                  <h4>公司地址*</h4>
                  <div>
                    {data?.ent_zip_code} {data?.ent_city} {data?.ent_district}{' '}
                    {data?.ent_address || '--'}
                  </div>
                </div>
              </Fragment>
            ) : null}
            {record.req_to === 'monthly' ||
            record.req_to === 'ent_vip_monthly' ? (
              <Fragment>
                <div className="row">
                  <h4>會員(聯絡人)</h4>
                  <div>{data?.contact_name || '--'}</div>
                </div>
                <div className="row">
                  <h4>抬頭</h4>
                  <div>{data?.ent_name || '--'}</div>
                </div>
                <div className="row">
                  <h4>統一編號</h4>
                  <div>{data?.gui_number || '--'}</div>
                </div>
                {/* <div className="row">
                    <h4>電子信箱</h4>
                    <div>{data?.email || "--"}</div>
                  </div> */}
                <div className="row">
                  <h4>產業類型</h4>
                  <div>{data?.industry_type}</div>
                </div>
                <div className="row">
                  <h4>聯絡電話</h4>
                  <div>{data?.ent_tel || '--'}</div>
                  <div>{data?.tel_ext}</div>
                </div>
                <div className="row">
                  <h4>傳真號碼</h4>
                  <div>{data?.fax || '--'}</div>
                </div>
                <div className="row">
                  <h4>聯絡地址</h4>
                  <div>
                    {data?.ent_zip_code} {data?.ent_city} {data?.ent_district}{' '}
                    {data?.ent_address || '--'}
                  </div>
                </div>
                <div className="row">
                  <h4>營業執照文件</h4>
                  <Anchor
                    type="button"
                    onClick={() => readFile(data.license_image)}>
                    {data.license_image || '--'}
                  </Anchor>
                </div>
                {/* <div className="row">
                    <h4>發票形式</h4>
                    <Selector
                      disabled={true}
                      value={data?.receipt_type || "--"}
                      onChange={e => {}}
                    >
                      <option selected="selected">--</option>
                      <option value="二聯">二聯</option>
                      <option value="三聯">三聯</option>
                    </Selector>
                  </div> */}
                {/* <div className="row">
                    <h4>公司負責人 手機 / 電話</h4>
                    <div>{data?.principal_tel || "--"}</div>
                  </div>
                  <div className="row">
                    <h4>聯絡人 手機 / 電話</h4>
                    <div>{data?.contact_tel || "--"}</div>
                  </div> */}
              </Fragment>
            ) : null}
          </div>
        </Block>
      ) : null}
    </Modal>
  );
}
