import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CSVLink} from 'react-csv';
import * as Ant from 'antd';
import moment from 'moment';
import OrderTable, {TableTypes} from '../../Components/OrderTable';
import MemberDialog, {TYPE as DIALOG_TYPE} from '../../Dialogs/MemberDialog';
import {MEMBER_TYPE} from '../../Utils/MemberUtil';
import {STAFF_TYPE} from '../../Utils/StaffUtil';
import {useOutlet} from 'reconnect.js';
const appConfig = require('../../data.json');
import qs from 'query-string';
import {isEnt} from '../../Domain/RoleValidator';
import BackButton from '../../Components/BackButton';
import {navigate} from 'gatsby';
import MonthlyBlock from '../../Blocks/MonthlyBlock';
import {Block as BlockIcon} from '@styled-icons/material/Block';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import Anchor from '../../Widgets/Anchor';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import MemberHistoryBlock from './MemberHistoryBlock';
import MemberEnterpriseBlock from './MemberEnterpriseBlock';
import MemberUpgradeBlock from './MemberUpgradeBlock';
import MonthlyDailog, {
  TYPE as MONTHLY_DIALOG_TYPE,
} from '../../Dialogs/MonthlyDialog';

const {RangePicker} = Ant.DatePicker;

const PAGINATION_INIT = {
  current: 1,
  pageSize: 5,
};

function FrozonHint({record}) {
  return record && record.is_frozen === true ? (
    <div
      style={{
        backgroundColor: '#6aa9bd',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        padding: 15,
      }}>
      <BlockIcon color="#dcf4ff" size="54" style={{marginRight: 10}} />
      <div>
        <div style={{color: 'white', fontSize: 16, fontWeight: 600}}>
          凍結狀態
        </div>
        <div style={{color: '#dcf4ff'}}>
          此會員將無法從電商平台下單，若付款完成，員工手動解除凍結狀態。
        </div>
      </div>
    </div>
  ) : null;
}

export default function MemberDetail({location}) {
  const {id: memberId} = qs.parse(location.search);
  const [record, setRecord] = useState();
  const [entData, setEntData] = useState();
  const [orders, setOrders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMonthlyDialog, setOpenMonthlyDialog] = useState(false);
  const [filters, setFilters] = useState({
    ...PAGINATION_INIT,
    from: null,
    to: null,
  });
  const [total, setTotal] = useState(0);
  const [csvReady, setCsvReady] = useState(false);
  const [totalOrders, setTotalOrders] = useState([]);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [orderLoading, setOrderLoading] = useState(false);

  const getRecord = useCallback(async () => {
    try {
      setLoading(true);
      let resp = await actions.getMember(memberId);
      setRecord(resp);

      if (resp.data) {
        let entData = JSON.parse(resp.data);
        setEntData(entData);
      }
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  }, [memberId, actions.getMember]);

  const getOrders = useCallback(async () => {
    setOrderLoading(true);
    let params = {
      buyer: memberId,
      from: filters.from,
      to: filters.to,
      offset: (filters.current - 1) * filters.pageSize,
      limit: filters.pageSize,
    };
    try {
      let resp = await actions.getOrders(params);
      setOrders(resp.results);
      setTotal(resp.count);
    } catch (err) {
      console.warn(err);
    }
    setOrderLoading(false);
  }, [actions.getOrders, memberId, filters]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  const frooze = async () => {
    delete record.avatar;
    try {
      let resp = await actions.editMember({
        ...record,
        is_frozen: record.is_frozen ? false : true,
      });
      Ant.message.success(
        resp.is_frozen ? `凍結此會員成功` : `取消凍結帳號成功`,
      );
      getRecord();
    } catch (err) {
      console.warn(err);
      Ant.message.error('凍結動作失敗');
    }
  };

  if (!record) {
    return (
      <Wrapper>
        <BackButton />
        <h2>找不到此會員資料</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />

        <Button
          style={{marginLeft: 10}}
          type="primary"
          onClick={() => setOpenDialog(DIALOG_TYPE.general)}>
          編輯會員
        </Button>
        <Button
          onClick={() => frooze()}
          type="primary"
          color={appConfig.colors.error}
          style={{marginLeft: 10}}>
          {record.is_frozen ? `取消凍結帳號` : `凍結帳號`}
        </Button>
        {record.user_type === 'monthly' && (
          <Button
            onClick={() => setOpenMonthlyDialog(MONTHLY_DIALOG_TYPE.SETTLE)}
            type="primary"
            style={{marginLeft: 10}}>
            出帳結算
          </Button>
        )}
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecord}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>

      <FrozonHint record={record} />

      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          {record && (
            <Block>
              <h2>會員資訊 #{record.id}</h2>
              <div>
                <div className="row">
                  <h4>會員編號</h4>
                  <div># {record.id}</div>
                </div>
                <div className="row">
                  <h4>建立時間</h4>
                  <div>{moment(record.created).format('YYYY-MM-DD HH:mm')}</div>
                </div>
                <div className="row">
                  <h4>顧客名稱</h4>
                  <div>{record.name}</div>
                </div>
                <div className="row">
                  <h4>登入方式</h4>
                  <div>
                    {record.social_type === 'default'
                      ? '信箱註冊'
                      : record.social_type}
                  </div>
                </div>
                {!appConfig.isLiteVersion && (
                  <div className="row">
                    <h4>會員群組</h4>
                    <div>{record.group_name}</div>
                  </div>
                )}
                <div className="row">
                  <h4>會員身份</h4>{' '}
                  {/* refactor: change these requests to ONE field */}
                  <div>{MEMBER_TYPE[`${record.user_type}`]}</div>
                </div>
                <div className="row">
                  <h4>員工身份</h4>
                  <div>{STAFF_TYPE[`${record.staff_type}`] || '無'}</div>
                </div>

                <div className="row">
                  <h4>行動電話</h4>
                  <div>{record.phone}</div>
                </div>
                <div className="row">
                  <h4>電子信箱</h4>
                  <div>{record.email}</div>
                </div>
                <div className="row">
                  <h4>地址</h4>
                  <div>
                    {record.zip_code} {record.city} {record.district}{' '}
                    {record.address}
                  </div>
                </div>
                <div className="row">
                  <h4>備註</h4>
                  <div>{record.note}</div>
                </div>
              </div>
            </Block>
          )}
        </Ant.Col>

        <Ant.Col span={12}>
          {record && isEnt(record) && (
            <MemberEnterpriseBlock
              record={record}
              entData={entData}
              redirect={false}
            />
          )}
        </Ant.Col>

        <Ant.Col span={6}>
          <Block>
            <h2>會員群組 / 會員身份</h2>
            <div className="divider" />
            <h2 style={{color: appConfig.colors.main}}>
              {' '}
              {!appConfig.isLiteVersion ? record.group_name : ''} /{' '}
              {MEMBER_TYPE[`${record.user_type}`]}
            </h2>
          </Block>
        </Ant.Col>
        <Ant.Col span={6}>
          <Block>
            <div
              className="row"
              style={{margin: 0, justifyContent: 'space-between'}}>
              <h2>剩餘加值點數</h2>
              <Button
                size="small"
                type="primary"
                onClick={() => setOpenDialog(DIALOG_TYPE.credit)}>
                編輯點數
              </Button>
            </div>
            <div className="divider" />
            <div
              className="row"
              style={{margin: 0, justifyContent: 'space-between'}}>
              <h2 style={{color: appConfig.colors.main}}>{record.credits}</h2>
              <div>點</div>
            </div>
          </Block>
        </Ant.Col>
        <Ant.Col span={6}>
          <Block>
            <div
              className="row"
              style={{margin: 0, justifyContent: 'space-between'}}>
              <h2>剩餘回饋紅利</h2>
              <Button
                size="small"
                type="primary"
                onClick={() => setOpenDialog(DIALOG_TYPE.credit)}>
                編輯紅利
              </Button>
            </div>
            <div className="divider" />
            <div
              className="row"
              style={{margin: 0, justifyContent: 'space-between'}}>
              <h2 style={{color: appConfig.colors.error}}>{record.bonus}</h2>
              <div>點</div>
            </div>
          </Block>
        </Ant.Col>
        <Ant.Col span={6}>
          <Block>
            <h2>會員訂單數量</h2>
            <div className="divider" />
            <div
              className="row"
              style={{margin: 0, justifyContent: 'space-between'}}>
              <h2>{total}</h2>
              <div>筆</div>
            </div>
          </Block>
        </Ant.Col>

        {!appConfig.isLiteVersion && (
          <Ant.Col span={24}>
            <MemberHistoryBlock member={record} />
          </Ant.Col>
        )}

        <Ant.Col span={24}>
          <Block>
            <h2>歷史訂單記錄</h2>
            <div className="divider" />
            <div>
              <OrderTable
                type={TableTypes.MONTHLY}
                loading={orderLoading}
                orders={orders}
                mounted={false}
                pagination={{
                  pageSize: filters.pageSize,
                  current: filters.current,
                  total,
                }}
                onPaging={(pagination) =>
                  setFilters((prev) => ({
                    ...prev,
                    pageSize: pagination.pageSize,
                    current: pagination.current,
                  }))
                }
              />
            </div>
          </Block>
        </Ant.Col>

        {record?.user_type === 'monthly' && (
          <Ant.Col span={24}>
            <MonthlyBlock member={record} />
          </Ant.Col>
        )}

        {record && isEnt(record) && (
          <Ant.Col span={12}>
            <MemberUpgradeBlock memberId={record.id} onUpdate={getRecord} />
          </Ant.Col>
        )}
      </Ant.Row>

      {record && (
        <div>
          {/* <Block>
            <div className="title">
              <h2>訂單資訊</h2>
              {!csvReady ? (
                <Button
                  onClick={async () => {
                    setOrderLoading(true);
                    try {
                      let resp = await actions.getOrders({
                        buyer: memberId,
                        from: filters.from,
                        to: filters.to,
                        no_page: true,
                      });
                      setTotalOrders(
                        resp.map((order) => ({
                          created: order.created,
                          display_id: order.display_id,
                          buyer_name: order.buyer.name,
                          amount: order.amount,
                        })),
                      );
                      setCsvReady(true);
                    } catch (err) {
                      console.warn(err);
                    }
                    setOrderLoading(false);
                  }}>
                  產生訂單
                </Button>
              ) : (
                <CSVLink
                  headers={[
                    { label: '建立時間', key: 'created' },
                    { label: '訂單編號', key: 'display_id' },
                    { label: '顧客姓名', key: 'buyer_name' },
                    { label: '總價', key: 'amount' },
                  ]}
                  data={totalOrders.map((order) => {
                    return {
                      created: ` ${order.created.slice(
                        0,
                        10,
                      )} ${order.created.slice(11, 16)}`,
                      display_id: order.display_id,
                      buyer_name: order.buyer_name,
                      amount: order.amount,
                    };
                  })}
                  filename={`會員${record.name}訂單`}>
                  <Button>匯出訂單</Button>
                </CSVLink>
              )}
            </div>

            <div style={{ margin: 10 }}>
              <RangePicker
                ranges={{
                  本日: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={(dates, dateStrings) => {
                  setFilters({
                    ...PAGINATION_INIT,
                    from: dateStrings[0],
                    to: dateStrings[1],
                  });
                }}
              />
            </div>
            
          </Block> */}
        </div>
      )}

      <MemberDialog
        profile={record}
        dialogType={openDialog}
        onUpdate={() => getRecord()}
        onClose={() => {
          setOpenDialog(false);
        }}
      />

      <MonthlyDailog
        type={openMonthlyDialog}
        buyer={record}
        onClose={() => setOpenMonthlyDialog(false)}
        onUpdate={getRecord}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
