import React, {useCallback, useEffect, useState} from 'react';
import MonthlyOrderTable from '../Components/MonthlyOrderTable';
import {useOutlet} from 'reconnect.js';
import {message} from 'antd';
import moment from 'moment';
import {navigate} from 'gatsby';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Row from '../Widgets/Row';
const appConfig = require('../data.json');

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function MonthlyBlock(props) {
  const {member} = props;
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');
  const [filters, setFilters] = useState({
    ...PAGINATION_INIT,
  });

  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const getRecords = useCallback(async () => {
    const {current, pageSize} = filters;

    if (member) {
      setLoading(true);
      try {
        const {results, count} = await actions.getMonthlyOrders({
          buyer_id: member.id,
          ordering: '-created',
          offset: (current - 1) * pageSize,
          limit: pageSize,
        });
        setRecords(results);
        setTotal(count);
      } catch (ex) {
        message.warning('取得月結單錯誤');
        console.warn(ex);
      }
      setLoading(false);
    }
  }, [member, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Block>
      <Row style={{justifyContent: 'space-between'}}>
        <div>
          <h2>月結對帳單</h2>
          {/* <div style={{ color: "#999", fontSize: 12 }}>
            點擊結算，將會把未結算的訂單都納入新增的月結單內，注意無法回復。
          </div> */}
        </div>
        <div style={{flex: 1}} />
        {/* {(moment(member.monthly_date).add(30, 'days').isSameOrBefore(new Date().toISOString().slice(0, 10))) && (
          <Button
            disabled={loading}
            css="margin-left:10px;"
            onClick={() => settle()}
          >
            結算
          </Button>
        )} */}
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecords}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>

      <div className="divider" />
      <MonthlyOrderTable
        loading={loading}
        records={records}
        mounted={true}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          changeFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Block>
  );
}
